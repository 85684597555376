.g-modal.oval-modal {
	border-radius: 22px !important;

	.g-modal-close {
		top: 15px;
		right: 15px;
	}

	.g-modal-content {
		border-radius: 22px !important;

		.g-modal-body {
			border-radius: 22px !important;
			padding: 35px 40px;
		}

		.g-modal-body--signin {
			width: 360px;
		}
	}
}

.responsive-wrap {
	width: 360px;
	margin: 0 auto;
	font-size: 13px;
}


@media (max-width: 415px) {
	.g-modal.oval-modal {
		.g-modal-content {
			.g-modal-body {
				padding: 25px 20px;
				width: auto;
			}
		}
	}	
}

@media (max-width: 400px) {
	.responsive-wrap {
		width: initial;
		min-width: initial;
		margin: 0 20px;
	}

	.g-modal-body .responsive-wrap {
		margin: 0;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset;
	-moz-box-shadow: 0 0 0 30px white inset;
	box-shadow: 0 0 0 30px white inset;
}

input::-ms-clear {
	display: none;
	height: 0;
	width: 0;
}

@keyframes shake {
	10%, 90% {
	  transform: translate3d(-1px, 0, 0);
	}
	
	20%, 80% {
	  transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
	  transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
	  transform: translate3d(4px, 0, 0);
	}
}
.form input.text, input.text {
	line-height: normal;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #ccc;
	font-size: 14px;
	border-radius: 5px;
	box-sizing: border-box;
	min-width: initial;
	width: 100%;
	color: #000;
	font-family: "Roboto", sans-serif !important;

	&:disabled {
		background: #f4f4f4;
		font-weight: bold;
		color: #000;
		border: 1px solid #eeeeee;
	}
	
	&.wrong-input {
	  border-color: #cc0000;
	  animation-name: shake;
	  animation-duration: 700ms;
	  animation-delay: 300ms;
	  animation-timing-function: ease-in-out;
	  animation-direction: alternate;
	  animation-fill-mode: forwards;
	}

}

textarea, input {
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: #ccc;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: #ccc;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: #ccc;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: #ccc;
	}

	&::placeholder {
		color: #ccc;
	}
}

.submitbut {
	width: 100%;
	border: none;
	outline: none;

}

.butt-wrap {
	height: 40px;
	width: 100%;
}

.butt,
.multiplatform-butt {
	.animate {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin: -10px 0 0 -10px;

		-webkit-animation: spin .5s linear infinite;
		-moz-animation: spin .5s linear infinite;
		-o-animation: spin .5s linear infinite;
		-ms-animation: spin .5s linear infinite;
		animation: spin .5s linear infinite;
	}

	.loading-image {
		background: url('/ZCDNURL/img/loading_button.svg') no-repeat center center;
		width: 20px;
		height: 20px;
		background-size: 20px 20px;
		margin: -10px 0 0 -10px;
	}

	&.windows {
		display: flex;
		align-items: center;
		justify-content: center;

		.loading-image {
			width: 16px;
			height: 16px;
			background-size: 16px 16px;
			position: static;
			margin: 0;
		}
	}
}

.multiplatform-butt {
	&.mac[disabled] {
		.loading-image {
			filter: invert(75%);
		}
	}

	&.ios[disabled] {	
		.loading-image {
			filter: invert(75%);
		}
	}
}

.butt {
	min-width: 0;
	border-radius: 12px;
	color: #fff;
	text-decoration: none;
	text-align: center;
	display: block;
	font-weight: 600;
	font-size: 16px;
	position: relative;
	width: 100%;
	padding: 0 10px;
	margin: 0 auto;
	box-sizing: border-box;
	overflow: hidden;
	cursor: pointer;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

/*
.loading {
	background: url('/ZCDNURL/img/loading_button.png') no-repeat center center;
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	margin: auto;

	&.animate {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin: -10px 0 0 -10px;

		-webkit-animation: spin .5s linear infinite;
		-moz-animation: spin .5s linear infinite;
		-o-animation: spin .5s linear infinite;
		-ms-animation: spin .5s linear infinite;
		animation: spin .5s linear infinite;
	}

	&.black {
		background: url('/ZCDNURL/img/loading_button_black.png') no-repeat center center;
	}
}
*/
.responsive-wrap {
	.butt {
		width: 100% !important;
	}
}

.butt.blue {
	background: #1bc9d3;
}

.butt.green {
	background: #86C600;
}

.butt.black {
	background: #000;
}

.butt.red {
	background: #FC354C;
}

.butt.inactive-butt {
	opacity: 0.5;
}

.form {
	.title {
		color: #000;
		font-size: 18px;
		text-align: center;
		display: block;
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	.subtitle {
		display: block;
		color: #999;
		font-size: 13px;
		text-align: center;
		font-weight: normal;
		margin: -2px 0 5px 0;
	}

	input.text {
		width: 100%;
		margin: 0 0 15px 0;
	}

	// label {
	// 	width: initial;
	// 	float: left;
	// 	cursor: pointer;
	// 	padding: 0 !important;
	// 	margin: 0 !important;
	// 	font-weight: normal;
	// 	color: #000;
	// 	font-size: 13px;

	// 	input.text {
	// 		vertical-align: middle;
	// 		margin: -1px 7px 0 0;
	// 		min-width: initial;
	// 		display: inline-block;
	// 	}

	// 	input[type=checkbox] {
	// 		vertical-align: middle;
	// 		margin: -1px 4px 0 0;
	// 	}
	// }

	label.vertical {
		display: block;
		float: none;
		cursor: default;

		input.text {
			margin: 5px 0 0 0;
		}
	}

	.error {
		color: red;
		text-align: center;
		line-height: 15px;
		margin: 8px 0 5px 0;
		min-width: initial;
		padding: 0;
	}
}

.login {
	margin: 0;
}

.signin, .signup {
	color: #999;

	.form-title {
		color: #000;
		font-size: 18px;
		text-align: center;
		display: block;
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	.form-subtitle {
		display: block;
		color: #999;
		font-size: 13px;
		text-align: center;
		font-weight: normal;
		margin: 10px 0 5px 0;
	}

	.or {
		text-align: center;
		display: block;
		margin: 10px 0 10px 0;
	}

	.inputs-bottom {
		margin: 0 0 15px 0;

		&:after {
			visibility: hidden;
	    display: block;
	    font-size: 0;
	    content: " ";
	    clear: both;
	    height: 0;
		}
	}

	.additional {
		text-align: center;
		margin: 10px 0 10px 0;
	}

	a {
		color: #17bed0;
		text-decoration: none;
	}
}

.loading-wrap {
	text-align: center;
	background: url('/ZCDNURL/img/loading.gif') no-repeat center center;
	min-width: 280px;
	min-height: 100px;
}

.fleft {
	float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
	cursor: pointer;
	padding: 0;
}

.fright {
	float: right;
}
