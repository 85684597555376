.fa {
	.upload-link-wrap {
		.upload-link {
			.ul-files {
				.heading {
					.name {
						padding-left: 0;
						padding-right: 10px;
					}
					.remove {
						float: left;
					}
					.size {
						float: left;
						padding-right: 5px;
						padding-left: 10px;
					}
				}

				.files {
					& > div:first-child {
						margin-left: -17px !important;
						margin-right: 0 !important;
					}

					& > div > div {
						max-width: 500px;
					}

					.name {
						.iconwrap  {
							line-height: 0px;
							margin: -1px 0 0 5px;
						}
					}
					.remove {
						float: left;
					}
					.size {
						float: left;
						padding-right: 5px;
						padding-left: 10px;
					}
				}
			}

			.ul-limits {
				& > :first-child {
					float: right;
					padding-left: 0;
					padding-right: 4px;

					b {
						display: inline-block;
					}
				}
				& > :last-child {
					float: left;
					padding-left: 4px;
					padding-right: 0; 

					b {
						display: inline-block;
					}
				}
			}
		}

		.progress-wrap .progress-status {
			& > :first-child {
				float: right;
			}
			& > :last-child {
				float: left;
			}
		}
	}
}
