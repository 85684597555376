.g-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);

    overflow-y: auto;
    overflow-x: hidden;

    text-align: center;
    z-index: 1999;

    @media (max-width: 768px) {
      &.g-overlay-auth-modal,
      &.g-overlay-backup_modal {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
}

html.g-modal-open:not(.dlink) {
  overflow: hidden;
}

html.g-modal-open:not(.dlink) body {
    overflow: hidden;
}

.g-modal {
    z-index: 3000;
    font-size: 12px;

    background: #fff;

    -moz-box-shadow: 3px 3px 5px rgba(0,0,0,0.25);
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);

    position: relative;
    margin: 30px auto;
    text-align: left;
    display: inline-block;

    @media (max-width: 768px) {
      &.auth-modal,
      &.backup_modal {
        width: ~"calc(100% - 40px)" !important;
        max-width: 440px;
        max-height: 100%;
      }

      &.auth-modal .responsive-wrap {
        width: auto;
      }

      &.backup_modal .processing {
        width: 100%;
      }

      &.backup_modal .backup-setup {
        min-width: 0px !important;
        padding: 0px !important;
      }

      &.backup_modal .account-info {
        height: auto !important;
        line-height: 1.4 !important;
        margin: 0px auto 10px auto;
      }
    }
}

.g-modal.modal-mobile {
    margin: 0;
}

.modal-mobile .modal-inner {
    max-height: 100%;
    overflow-y: auto;
}

.g-modal a.button {
    text-decoration: none;
}

.g-modal a:hover {
    text-decoration: none;
}

.g-modal .audioplay {
    font-size: 13px;
}

.g-modal .tblines td {
    border-bottom: 1px solid #F0F0F0;
    padding: 10px 0px 12px 0;
}

.g-modal .hasshort tr.short, .g-modal .noshort tr.createshort {
    display: table-row;
}

.g-modal .hasshort tr.createshort, .g-modal .noshort tr.short {
    display: none;
}

.g-modal .tblines td b {
    font-size: 12px !important;
    margin: 0 25px 0 0;
    padding: 0;
}

.g-modal .tblines td img {
    margin-right: 4px;
    vertical-align: middle;
}

.g-modal .finfo .tblines td {
    padding: 5px 0 7px 0;
}

.g-modal .sharetbl, .g-modal .sharetbl td {
    border-collapse: collapse;
}

.g-modal .sharetbl {
    width: 100%;
}

.g-modal .sharetbl td {
    background: #FAFAFA;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F0F0F0;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
}

.g-modal .sharetbl select {
    width: auto;
    padding-right: 0;
}

.g-modal .shperm label {
    cursor: pointer;
    margin-right: 5px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    margin-left: 2px;
    font-weight: normal;
}

.g-modal .shperm input {
    vertical-align: middle;
    margin-left: ;
}

.g-modal .tblines td a { color: #000; }
.g-modal .tblines td a:hover, .g-modal .tblines td a.hover { text-decoration: none }

.g-modal textarea {
    font-size: 12px;
}

.no-title .g-modal-title {
    display: none;
}

.g-modal-title {
    font-size: 12px;
    color: #7b7b7b;
    background: #eeeeee;
    border-bottom: 1px solid #e9e9e9;
    height: 33px;
    font-weight: bold;
    line-height: 33px;
    padding-left: 10px;
}

.g-modal-title .g-modal-title-text {
    margin-right: 35px;
}

.g-modal-title .g-modal-title-text span {
    color: #aaa;
}

.g-modal-title .g-modal-title-text a {
    color: #000;
    font-size: 12px;
    font-weight: normal;
}

.g-modal b {
    font-size: 13px;
    display: inline-block;
    // margin-bottom: 7px;
}

.g-modal .action {
    margin-top: 10px;
}
.g-modal-content {
    z-index: 4000;
    background: #fff;
}

.g-modal .loading, .lightbox .loading {
    background: #333;
    opacity: 0.4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.lightbox .loading {
    background: none;
    opacity: 1;
}

.g-modal .processing {
    display: none;
    border-radius: 2px;
    width: 300px;
    background: #fff;

    -moz-box-shadow: 4px 4px 7px rgba(0,0,0,0.5);
    -webkit-box-shadow: 4px 4px 7px rgba(0,0,0,0.5);
    box-shadow: 4px 4px 7px rgba(0,0,0,0.5);
}

.g-modal .processing .information {
    background: #fefefe url('/ZCDNURL/img/processing.gif') no-repeat center center;
    height: 260px;
    line-height: 390px;

    color: #999;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    text-align: center;
}

.g-modal .processing .stopprocess {
    padding: 20px;
    text-align: center;
    background: #f4f4f4;
}

.g-modal .processing .stopprocess .stopbut {
    margin: auto;
    background: rgb(39,40,41);
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    -moz-box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
    -webkit-box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
    box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
}

.g-modal .processing .stopprocess .stopbut:hover {
    background: rgb(29,30,31);
    -moz-box-shadow: 0px 1px 0px rgba(255,255,255,0.5) ,inset 0px 5px 4px rgba(0,0,0,0.35);
    -webkit-box-shadow: 0px 1px 0px rgba(255,255,255,0.5) ,inset 0px 5px 4px rgba(0,0,0,0.35);
    box-shadow: 0px 1px 0px rgba(255,255,255,0.5) ,inset 0px 5px 4px rgba(0,0,0,0.35);
    color: #FF3737;
}

.g-modal .processing .stopprocess .stopbut img {
    margin-right: 15px;
    margin-top: -2px;
}

.g-modal .loadcenter, .lightbox .loadcenter {
    border: 1px solid #999;
    border-radius: 2px;
    width: 300px;
    height: 220px;
    background: #fefefe url('/ZCDNURL/img/processing.gif') no-repeat center center;
    position: absolute;
    top: 50%;
    left: 50%;

    margin-left: -150px;
    margin-top: -110px;
    line-height: 179px;
    text-align: center;
    font-family: tahoma;
    font-size: 13px;
}

.loadcenter span { text-decoration
  : underline; cursor: pointer; }
.loadcenter span:hover { text-decoration: none; }

.g-modal .g-modal-close, .lightbox .g-modal-close {
    cursor: pointer;
    position: absolute;
    right: 00px;
    top: 0;
    margin-top: 0;

    width: 40px;
    height: 34px;

    background-size: 11px 11px;
    @mixin retina "/ZCDNURL/img/header/close-modal.png";
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.35;

    &:hover {
        opacity: 1;
    }
}

.g-modal.oval-modal .g-modal-close {
  background: rgba(0, 0, 0, 0.1) url('/ZCDNURL/img/wclose.png') no-repeat center center;
  border-radius: 30px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  right: 10px;
  top: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.g-modal.oval-modal, .oval-modal .g-modal-content {
    border-radius: 10px;
}

.megaoval-modal {
	border-radius: 15px;

	.modal-inner {
		border-radius: 15px;

		.g-modal-content {
			border-radius: 15px;
		}
	}
}

.g-modal-body {
	border-radius: 15px;
	padding: 20px;
}

.g-modal label {
    font-size: 13px;
    padding-bottom: 10px;
}
.g-modal label.apply-all {
    font-size: 13px;
    padding: 0;
    margin-left: 5px;
}

.g-modal label.intbl {
    padding: 2px 0 2px 0;
}

.g-modal label.big {
    font-size: 19px;
    font-weight: normal;
}

.butt-area {
  button {
    margin-right: 10px;
    min-width: 120px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.g-modal.backup_modal {
  border-radius: 17px;
  overflow: hidden;

  .g-modal-inner {
    padding: 35px 20px;

    .g-modal-title {
      font-family: "Roboto", sans-serif;
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.21px;
      text-align: center;
      color: #000000;
      background-color: transparent;
      border-bottom: none;
      padding-left: 0px;

      .g-modal-title-text {
        margin-right: 0px;
      }
    }

    .account_info {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.15px;
      text-align: center;
      color: #000000;

      b {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
      }
    }

    .butt-area {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0px;

      .button {
        border-radius: 6px;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.15px;
        text-align: center;
        color: #ffffff;

        &.greenbut {
          order: 2;
        }

        &.redbut {
          background-color: #f73c3c;
        }

        &.darkbut {
          order: 1;
          background-color: #e9e9e9;
          color: #444444 !important;
        }
      }
    }
  }
}
