.mn-holder {
  position: absolute;
}

.dropdown-holder {
  display: inline-block;
}

/* base menu class */
ul.mn {
	z-index: 9000;
	border-top: 2px solid #1BCED8;
	-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

	padding: 0;
	background: #fff;
    /*width: 160px;*/
}

img.filter-icon {
    float: left;
    margin-top: 7px !important;
    margin-right: 10px !important;
}

.check-icon {
    display: inline-block;
    width: 12px;
    height: 9px;
    margin-left: 15px;

    background-size: 12px 9px;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    @mixin retina "/ZCDNURL/img/full-text-search/ch.png";
}

.mnnew ul > a:hover, .mnnew ul > a.active {
    // remove opacity from filter icons @SearchBar
    // img.filter-icon {
    //     opacity: 0.4;
    // }

    li {
        color: black;
    }
}

ul.mn.fileopts {
    min-width: 160px;
}

.darkbox-share {
    z-index: 2502;
    width: 180px;
    height: 125px;
}

.darkbox-share .tip {
    position: absolute;
    bottom: 3px;
    right: 50%;
    left: 50%;
    margin-left: -7px;
}

.darkbox-share.alone .tip {
    right: 23px;
}

.darkbox-opts {
    z-index: 2502;
    width: 200px;
    height: 328px;
}

.darkbox-opts.videogallery {
    height: 271px;
}

.darkbox-opts.gallery {
  height: 300px !important;
}

.darkbox-opts.videogallery a.openorig {
    display: none;
}

.darkbox-opts .tip {
    position: absolute;
    bottom: 4px;
    right: 23px; /* 60 / 2 - 14 / 2 */
}

.darkbox.gallery .darkbox-opts.gallery {
    background-color: transparent;
}

ul.darkmn {
    z-index: 2501;
    list-style: none;
    font-size: 12px;
    background: #232323;
    padding: 8px 1px;
}

ul.darkmn > a {
    color: #8a8a8f;
    text-decoration: none;
    height: 29px;
    line-height: 29px;
    display: block;
    padding-left: 10px;
}

ul.darkmn a:hover {
    background-color: #000;
    color: #FFF;
}

ul.darkmn a li {
    display: block;
    height: 29px;
    line-height: 30px;
}

ul.darkmn a li img {
    margin-right: 5px;
    margin-top: -2px;
}

.mnnew {
    z-index: 1000000;
}

.mnnew ul {
    /*border: 1px solid #dbdbdb;*/

    border-radius: 5px;
    background: #fff;
    padding: 4px 0 4px 0px;
    list-style: none;

    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.45), 4px 4px 10px rgba(0, 0, 0, 0.15);

    margin: 0;
}

/*
.mnnew.top ul {
    border-bottom-width: 2px;
}

.mnnew.bottom ul {
    border-top-width: 2px;
}
*/

.mn-blue {
    border-color: #1BCAD4;
}

.mnnew ul > a {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: block;
  text-decoration: none !important;

  &.border-both {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }

  &.border-top {
    border-top: 1px solid #f0f0f0;
  }

  &.border-bottom {
    border-bottom: 1px solid #f0f0f0;
  }
}

.mnnew a li {
    color: #2f2f2f;
    opacity: 1;
    font-size: 12px;
    list-style: none;

    height: 32px;
    line-height: 32px;
    box-sizing: border-box;
    min-width: 80px;
    display: inline-block;
}

.mnnew a.border-both li {
  height: 30px;
  line-height: 30px;
}

.mnnew ul > a li img {
    margin-right: 5px;
    margin-top: -2px;
}

.mnnew ul > a:hover, .mnnew ul > a.active {
  background: #f4f4f4;
}

.mnnew ul > a.filter-item  {
    padding-right: 15px;
  }

.mnnew ul > a.active  {
  background: #fff !important;

  li {
    opacity: 0.6;
    /*color: #eee;*/
  }
}

.mnnew ul > a.not-available {
    cursor: default;

    img.filter-icon {
        opacity: 0.4;
    }

    li {
        opacity: 0.2;
    }

    &:hover {
        background: #fff !important;
    }
}

.mnnew:hover ul > a.active {
    background: #fff !important;
}

.mnnew ul > a {
    position: relative;
}

.mnnew ul > a.active::before {
    content: ' ';
    width: 12px;
    height: 9px;
    display: block;
    background-size: contain;
    position: absolute;
    right: 14px;
    top: 12px;
    @mixin retina "/ZCDNURL/img/full-text-search/ch.png";
}

.mnnew ul > a.active:hover {
    /*background: #f4f4f4 !important;*/
}

.mnnew a:hover li, .mnnew a.active li {
  /*color: #aaa;*/
}
.mnnew .csortnew-ico {
    display: block;
    height: 46px;
    width: 33px;

    background-position: center right;
    background-repeat: no-repeat;
    background-size: 18px 19px;
    @mixin retina "/ZCDNURL/img/grid/sort.png";
}

.mnnew:hover .csortnew-ico {
  @mixin retina "/ZCDNURL/img/grid/sort-active.png";
}

.mnnew.leftdir a.inner-username {
    padding: 0 10px 4px 10px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    cursor: default;
}

.mnnew {
  .tip {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;

    border-width: 7px;

    .tip-inner {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 6px;

      border-bottom-color: #fff;

      left: -6px;
    }
  }

  &.bottom {
    .tip {
      top: -7px;
      border-top-width: 0;
      border-bottom-color: #cbcbcb;

      .tip-inner {
        top: 1px;
        border-bottom-color: #fff;
        border-top-width: 0;
      }
    }
  }

  &.top {
    .tip {
      border-bottom-width: 0px;
      border-top-color: #cbcbcb;
      bottom: -7px;

      .tip-inner {
        top: -7px;
        border-top-color: #fff;
        border-bottom-width: 0;
      }
    }
  }
}

/*
.mnnew.bottom .tip {
    top: -6px;
    border-top-width: 0;
    border-bottom-color: #cbcbcb;
}

.mnnew.bottom.white-tip .tip {
    border-bottom-color: #fff;
}

.mnnew.bottom.mn-blue .tip {
    border-bottom-color: #1BCAD4;
}

.mnnew.top .tip .tip {
    bottom: -6px;
    border-bottom-width: 0;
    border-top-color: #000;
}

.mnnew.top.white-tip .tip {
    border-top-color: #fff;
}

.mnnew.top.mn-blue .tip {
    border-top-color: #1BCAD4;
}
*/

.right-arrow {
    background: url('/ZCDNURL/img/mnew-arrow.png') no-repeat top 0px right 8px;
    height: 4px;
}

.right-arrow-gear {
    background: url('/ZCDNURL/img/mnew-arrow.png') no-repeat top 0px right 4px;
    height: 4px;
}

.right-arrow-blue {
    background: url('/ZCDNURL/img/mnew-arrow-blue.png') no-repeat top 0px right 6px;
    height: 4px;
}

.right-arrow-blue-perm {
    background: url('/ZCDNURL/img/mnew-arrow-blue.png') no-repeat top 0px right 4px;
    height: 4px;
}

.mn-blue ul {
    border-top-color: #1BCAD4;
}

.mnew-heighter {
    -moz-box-shadow: 4px 4px 3px rgba(0,0,0,0.07) ,0px 0px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 4px 4px 3px rgba(0,0,0,0.07) ,0px 0px 1px rgba(0,0,0,0.2);
    box-shadow: 4px 4px 3px rgba(0,0,0,0.07) ,0px 0px 1px rgba(0,0,0,0.2);
}

.mnew-heighter ul {
  /*padding: 0;*/
}

.mnew-heighter a {
    opacity: 1;
    padding: 13px 15px 13px 14px;
}

.mnew-heighter a:hover {
    background: #f9f9f9;
}

.mnew-heighter a:hover img.ina, .mnew-heighter a.hasshare img.ina, .mnew-heighter a img.act {
    display: none;
}

.mnew-heighter a:hover img.act, .mnew-heighter a.hasshare img.act, .new-heighter a img.ina {
    display: inline-block;
}

.mnew-heighter a li {
    opacity: 1;
    height: auto;
}

.mnew-heighter a li img {
    margin-top: 7px !important;
    margin-right: 20px !important;
    float: left;
}

.mnew-heighter .mn-text {
    float: left;
    max-width: 260px;
}

.mnew-heighter .mn-text .mn-title {
    font-weight: bold;
    line-height: 12px;
    margin-bottom: 2px;
}

.mnew-heighter .mn-text .mn-explain {
    line-height: 14px;
    font-size: 11px;
    color: #999;
}

.mn-filters a li img {
    margin-right: 10px;
}

.mn-filters a {
    padding-right: 10px;
}

/************************************************/

ul.mn a {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: normal;
  background: #fff;

  display: block;
  cursor: pointer;
  padding: 0 20px 0 15px;
  text-decoration: none;

  list-style: none;
}

ul.mn.leftdir a {
    padding-left: 25px;
    background-color: #fff;
}

ul.mn a.sub {
    background: #fff url('/ZCDNURL/img/sub-right.png') no-repeat center right 14px;
}

ul.mn.leftdir a.sub {
    background: #fff url('/ZCDNURL/img/sub-left.png') no-repeat center left 14px;
}

ul.mn a img {
    margin-right: 5px;
    margin-top: -2px;
    display: inline-block;
}

ul.mn a img.ina { display: none; }
ul.mn a:hover img.act, ul.mn a.opn img.act { display: none; }
ul.mn a:hover img.ina, ul.mn a.opn img.ina { display: inline-block; }
ul.mn a a:first-child { border-top: none; }
ul.mn a li {
    height: 32px;
    line-height: 32px;

    color: #333;
    text-decoration: none;
    list-style: none;

    display: block;
    border-top: 1px solid #f9f9f9;

    padding-right: 10px;

    /*white-space: nowrap;*/
}

.mn a:hover, ul.mn a.opn, .mn a.active  {
    background-color: #f9f9f9 !important;
}

.mn a.sub:hover, .mn a.sub.opn {
    background: #f9f9f9 url('/ZCDNURL/img/sub-right.png') no-repeat center right 8px;
}

.mn.leftdir a.sub:hover, .mn.leftdir a.sub.opn {
    background: #f9f9f9 url('/ZCDNURL/img/sub-left.png') no-repeat center left 8px;
}

/* mnnew */

.mnnew.leftdir ul > a { /* leave space for left sub icons */
    min-width: 105px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
}

.mnnew a.sub {
    background: #fff url('/ZCDNURL/img/sub-right.png') no-repeat center right 8px;
}

.mnnew.leftdir a.sub {
    background: #fff url('/ZCDNURL/img/sub-left.png') no-repeat center left 8px;
}

.mnnew ul > a:hover, ul > .mnnew a.opn, ul > .mnnew a.active  {
    background-color: #f9f9f9 !important;
}

.mnnew a:hover li, .mnnew a.opn li, .mnnew a.active li  {
    opacity: 1;
}

.mnnew a.sub:hover, .mnnew a.sub.opn {
    background: #f9f9f9 url('/ZCDNURL/img/sub-right.png') no-repeat center right 8px;
}

.mnnew.leftdir a.sub:hover, .mnnew.leftdir a.sub.opn {
    background: #f9f9f9 url('/ZCDNURL/img/sub-left.png') no-repeat center left 8px;
}

.mn-perm a {
    padding-left: 8px;
    padding-right: 8px;
}

.mn-perm a li {
    min-width: initial;
}

.mn-perm.leftdir a {
    padding-left: 8px;
}

.mn-perm {
    min-width: 80px !important;
}

/************************************************/

ul.mn hr {
    display: block;
    height: 1px;
    border: 0;
    color: #F4F4F4;
    background: #F4F4F4;
    margin: 0 7px 0 7px;
    padding: 0;
}

.leftdir {
    min-width: 80px;
}

.left hr {
  width: 160px!important;
  margin: 0 0 4px 0;
}

.left .uplplc hr {
    margin: 0 auto;
}

ul.mn li.download {
    background: url(/ZCDNURL/img/drop-down-download.png) no-repeat 10px center;
}

ul.mn li img.act, ul.mn li:hover img.ina, ul.mn li.active img.ina {
    display: inline-block;
}

ul.mn li img.ina, ul.mn li:hover img.act, ul.mn li.active img.act {
    display: none;
}

.mnnew a li span.badge {
  background: #86c600;
  text-transform: uppercase;
  line-height: 14px;
  height: 14px;
  font-size: 10px;
  margin: -2px 0 0 8px;
  color: #fff;
  display: inline-block;
  padding: 0 4px 0 4px;
  vertical-align: middle;
}
