.market-text {
	font-size: 16px;
	padding: 40px 10px;
	text-align: center;
	border-top: 1px solid #eee;

	h4 {
		font-size: 20px;
    margin: 0 auto 5px;
	}
}

.upload-link-wrap {
	font-family: 'Roboto', sans-serif;

	.responsive-wrap {
		width: 100%;
	}

	.register-text-or {
		color: #a19f9d;
		opacity: 1;
	}

	.drop-zone {
		display: none;
		width: 100%;
		height: 100%;
		line-height: 100%;
		position: absolute;

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.drop-zone-title {
			position: absolute;
			width: 100%;
			height: 100px;
			line-height: 100px;
			margin-top: -50px;
			text-align: center;
			top: 50%;
			left: 0;
			right: 0;
		}
	}

	&.dragging {
		border: #666 3px dashed;
		position: relative;

		.drop-zone {
			display: block;
		}

		.upload-link {
			visibility: hidden;
		}
	}

	.upload-link {
		width: 450px;
		margin: -15px auto 40px auto;

		h1 {
			&.subheader {
				font-size: 16px;
				font-weight: normal;
				height: 16px;
				line-height: 16px;
			}

			margin: 35px 0;
			font-size: 20px;
			height: 20px;
			line-height: 20px;
		}

		.title {
			font-weight: bold;
		}

		.loading {
			padding: 40px;
			text-align: center;
		}

		.error {
			text-align: center;
			margin: 10px 0 10px 0;
			padding: 15px;
			font-size: 14px;
			line-height: 18px;
			color: #ff4000;
			border: 1px solid #f7f1df;
			border-radius: 5px;
			background: #fff9e6;

			&.noerror {
				display: none;
			}
		}

		.upload-link-box {
			border: 1px solid #e8e8e8;
			border-radius: 7px;
			font-size: 15px;
			background-color: #fafafa;

			.box-top {
				padding: 15px;
				text-align: center;
			}

			.box-bottom {
				border-top: 1px solid #e8e8e8;
				padding: 15px;
				font-size: 13px;
				font-weight: normal;
				word-break: break-word;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}

		.ul-error {
			background: #fff9e6;
			color: #ff4000;
			font-size: 12px;
		}

		.ul-limits {
			border-top: 1px solid #e8e8e8;
			line-height: 39px;
			font-size: 13px;

			& > :first-child {
				float: left;
				padding-left: 4px;
			}

			& > :last-child {
				float: right;
				padding-right: 4px;
			}

			&.empty {
				border-top: none;
			}
		}


		.ul-files {
			border: dashed 1px #c1eff9;
			border-radius: 7px;
			margin-bottom: 40px;

			&.collapse {
				border: solid 1px #e8e8e8;
			}

			.name {
				/*
					needs to be block in order for the 'X' and the size to be above
					the name
				*/
				/*display: inline;*/
				color: #000;
				font-size: 15px;
				padding-left: 5px;
				padding-right: 5px;

				.iconwrap {
					float: left;
					margin: 8px 5px 8px 0;
				}
			}

			.size {
				float: right;
				font-size: 13px;
				padding-right: 10px;
				padding-left: 5px;
			}

			.remove {
				cursor: pointer;
				width: 40px;
				height: 40px;
				float: right;
				background: url('/ZCDNURL/img/ul/remove-file.png') no-repeat center center;

				&:hover {
					background-image: url('/ZCDNURL/img/ul/remove-file-over.png');
				}
			}

			.heading {
				height: 40px;
				line-height: 40px;
				font-size: 13px;
				font-weight: 500;
				border-bottom: solid 1px #e8e8e8;
				background-color: #fafafa;
				border-top-right-radius: 7px;
				border-top-left-radius: 7px;
				color: #000;

				.name {
					display: inline;
					font-size: 13px;
					padding-left: 10px;
					padding-right: 0;
				}

				.size {
					font-size: 13px;
				}

				.remove {
					/* background: none; */
					color: #1ac4cc;
					text-decoration: underline;
					width: 40px;
					/* padding-right: 10px; */
					text-align: right;
					font-size: 12px;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.files {
				width: 100%;
				/*
				max-height: 246px;
				overflow-y: auto;
				*/

				& > div > div {
					height: 40px;
					line-height: 40px;
					border-bottom: 1px solid #e8e8e8;
				}

				& > div > div:hover {
					background: #fff;

					.name {
						font-weight: normal;
					}
				}
			}

			input {
				display: none;
			}

			.upload-file-wrapper {
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;

				&.collapse {
					flex-direction: row;
					width: 100%;
					height: 40px;
					padding: 0 10px;
					box-sizing: border-box;
					background-color: #fafafa;
					border-bottom-right-radius: 7px;
					border-bottom-left-radius: 7px;
				}

				.upload-click {
					text-align: center;
					margin: 15px 0 15px 0;

					div {
						color: #27c7cf;
						font-weight: 500;
						font-size: 16px;
						cursor: pointer;

						&:hover {
							text-decoration: none;
						}
					}

					span {
						font-size: 13px;
						line-height: 18px;
					}
				}

				.upload-click-collapse {
					background-color: #fafafa;
					color: #17bed0;
					font-size: 16px;
					font-weight: 500;
					width: 100%;
					text-align: center;
				}
			}
		}

		label {
			display: block;
		}

		.link-form {
			input[type=text], input[type=password] {
				height: 40px;
				line-height: 40px;
				font-size: 15px;
				padding: 0;
				text-indent: 10px;
				box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
				border: solid 1px #c1c1c1;
				background-color: #ffffff;
				border-radius: 5px;
				width: 100%;
			}

			input[type=password] {
				box-shadow: none;
			}
		}
		
		.ul-uploader {

		}

		.ul-uploadbutton {
			text-align: center;

			div {
				display: inline-block;
				max-width: 450px;
				width: 100%;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				text-align: center;
				border-radius: 7px;
				margin: 30px auto 100px auto;
				cursor: pointer;
				opacity: 0.4;
				box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.05);
				background-color: #17bed0;
				color: #ffffff;
			}

			&.active {
				div {
					opacity: 1;
				}
			}
		}
}

	.progress-wrap {

		.progress {
			background: #e4e4e4;
			height: 6px;
			position: relative;

			.fill {
				background: #1bcad4;
				position: absolute;
				height: 100%;
			}
		}

		.progress-status {
			display: flex;
			color: #959595;
			font-size: 14px;
			justify-content: space-between;
			height: 40px;
			padding: 0 10px;
			align-items: center;

			& > :first-child {
				float: left;

				& > span {
					&:first-child {
						margin-right: 2px;
					}

					&:last-child {
						margin-left: 2px;
					}
				}
			}

			& > :last-child {
				float: right;
			}
		}
	}

	label {
		.label {
			margin: 10px 0 3px 2px;
		}
		input {

		}
	}

	span.note {
		color: #999;
		font-size: 13px;
	}

	.ulbutton {
		text-decoration: none;
		display: inline-block;
		width: 240px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		text-align: center;
		border-radius: 5px;
		margin: 30px auto 100px auto;
		background: #27c7cf;
		opacity: 0.4;
		cursor: pointer;
		color: #fff;
	}

	.butt-wrap {
		text-align: center;

		.ulbutton {
			&.active {
				opacity: 1;

				&:hover {
					cursor: not-allowed;
					opacity: 0.8;
				}
			}
		}
	}
}

@media (max-width: 520px) {
	.upload-link-wrap {

		.upload-link {
			width: auto;
			margin: 0 15px 0 15px;

			.ul-uploadbutton {
				div {
					margin-bottom: 20px;
				}
			}
		}
	}
}
