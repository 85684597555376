.header-row {
  font-size: 11px;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  background: #ffffff;

  .ReactVirtualized__Table__headerColumn {
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0 0 0 10px;
    box-sizing: border-box;
    color: #888888;
    text-transform: none;
    background: #fff;
    font-weight: normal;
    outline: none;

    &.value {
      padding: 0;
    }
  }
}

.ReactVirtualized__Table__Grid {
  outline: none;

  .row {
    outline: none;
    font-size: 11px;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;

    .ReactVirtualized__Table__rowColumn {
      border-right: 1px solid #f4f4f4;
      box-sizing: border-box;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding-left: 10px;

      &.value {
        text-align: center;
        padding: 0;
        color: #aaa;
      }

      &:first-child {
        border-left: 1px solid #f4f4f4;
      }
    }

    &.even-row {
      background: #fafafa;
    }

    &.odd-row {
      background: #fff;
    }
  }
}
